import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Loader from "@amzn/meridian/loader";

import { Init } from "./config/auth";
// lazy loading App allows React Suspense to kick in while app is being loaded
const App = React.lazy(() => import("./components/app"));

Init();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <React.Suspense fallback={<Loader/>}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
